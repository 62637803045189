





























import Vue from "vue";
import {getUserRole} from "@/utils/helpers";
import {UserAuthorisationsVenuesData, VenueData} from "@/types";
import {mapGetters,mapActions} from "vuex";

export default Vue.extend({
  name: "user-authorisations" as string,

  data() {
    return {
      currentPage: 1,
      perPage: 42
    }
  },
  async created() {
    await this.setUsers();
  },

  computed: {
    ...mapGetters("userModule", {
      userVenuesAuthorisations: "GET_LOGGED_USER_VENUES_PERMISSIONS",
      users: "GET_USERS",
      userHasAccess: "GET_USER_HAS_ACCESS"
    }),
    ...mapGetters("companyModule",{
      companyId: "GET_COMPANY_ID"
    }),
    totalUserVenuesAuthorisations(): number {
      return this.userVenuesAuthorisations.length;
    },
    title(): string {
      return this.userVenuesAuthorisations.length ? this.$t('My authorisations') : this.$t('No authorisations')
    },
    userVenuesAuthorisationsList() {
      const userId = localStorage.getItem("cassia_user_id");
      let currentUser = this.users.find(user => user.id == userId);
      if(currentUser){
          currentUser.roles.map((role,index) =>{
          if(role.id === 5 && currentUser.roles.length > 1){
            currentUser.roles.splice(index, 1)
          }
          })
      }
      if(!this.userHasAccess && currentUser){
      return currentUser.venueAuthorizedToUser.filter(venue => venue.roleId !== 1 && venue.id)
      }
      return currentUser ? currentUser.venueAuthorizedToUser : []
    }
  },

  methods: {
    ...mapActions("userModule", {
      setUsers: "FETCH_USERS",
    }),
    userRole(id: number): string {
      return getUserRole(id);
    },
    userRoleClass(id: number): string {
      if (id === 1) return "admin";
      if (id === 2) return "venue-manager";
      if (id === 3) return "marketer";
      if (id === 4) return "accountant";
    },
    userRoleName(value: string): string {
      if (value === "Owner") return "Admin";
      if (value === "Admin") return "Venue manager";
      if (value === "Marketer") return "Marketer";
      if (value === "Accountant") return "Accountant";
    }
  },
  watch:{
    companyId:{
     async handler(value){
        await this.setUsers();
      }
    }
  }
})
