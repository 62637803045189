


































import Vue from "vue";
import Layout from "@/router/layouts/main.vue";
import UserInfo from "@/components/my-profile/UserInfo.vue";
import UserAvatar from "@/components/my-profile/UserAvatar.vue";
import UserChangePassword from "@/components/my-profile/UserChangePassword.vue";
import UserAuthorisations from "@/components/my-profile/UserAuthorisations.vue";
import {mapActions, mapGetters} from "vuex";
import {UserData} from "@/types";

export default Vue.extend({
  name: "user-profile" as string,

  components: {
    Layout,
    "user-info": UserInfo,
    "user-avatar": UserAvatar,
    "user-change-password": UserChangePassword,
    "user-authorisations": UserAuthorisations
  },

  data() {
    return {
      title: "My profile",
      edit: false,
      updatedUserData: {} as UserData,
      isValid: true
    }
  },

  async mounted(): Promise<void> {
    await this.setUserRoles();
    await this.setUserAuthorisations();
    if (!!this.user.image_last_updated) {
      await this.setImage({id: this.user.id, imageType: "clientzone_user"});
    }
  },

  computed: {
    ...mapGetters("userModule", {
      user: "GET_LOGGED_USER"
    }),
    ...mapGetters("companyModule", {
      companyId: "GET_COMPANY_ID"
    })
  },

  methods: {
    ...mapActions("userModule", {
      updateUser: "UPDATE_USER",
      setUserRoles :"FETCH_USER_ROLES",
      setUserAuthorisations: "FETCH_USER_AUTHORISATIONS"
    }),
    ...mapActions("globalModule", {
      setImage: "FETCH_ENTITY_IMAGE"
    }),
    ...mapActions("venueModule", {
      setVenues: "FETCH_VENUES"
    }),
    validate(value: boolean): void {
      this.isValid = value;
    },
    newUserData(userData: UserData): void {
      this.updatedUserData = userData;
    },
    async updateUserData(): Promise<void> {
      if (!this.isValid) return;

      await this.updateUser(this.updatedUserData);
      this.edit = false;
    }
  },

  watch: {
    companyId: {
      async handler(newValue: number, oldValue: number): Promise<void> {
        if (newValue != oldValue) {
          await this.setVenues({fetchRatings: false, name: ''});
          await this.setUserAuthorisations();
        }
      }
    }
  }
})
